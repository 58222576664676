import { Flex, Text } from "rebass";
import Bar from "../bar";
import React from "react";
import { oppositeColors } from "../../gatsby-plugin-theme-ui";
import { BlurredSpan, Description, HeaderContainer } from "../posed";
import { getThemeColor } from "../../utils";
import { useColorMode } from "theme-ui";

const FirstSlide = ({ title = '', subtitle, description, color = 'text', specification, pose, sx = {} }) => {
	const [colorMode] = useColorMode();
	const textShadow = `1px 1px 2px ${getThemeColor(oppositeColors[color], colorMode)}`;

	return (
		<Flex
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			maxWidth={[null, 600, 800, 900]}
			px={[3, 0]}
			sx={{
				margin: 'auto',
				...sx
			}}
		>
			{title && (
				<HeaderContainer
					as="h1"
					fontSize={[6, 7, 54, 65]}
					color={color}
					textAlign="center"
					sx={{ zIndex: 2, textShadow }}
					pose={pose}
				>
					{title.split(' ').map((text, i) => <BlurredSpan key={i}>{text} </BlurredSpan>)}
				</HeaderContainer>
			)}
			<Description
				mt={-1}
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				pose={pose}
			>
				{subtitle && (
					<Bar fontSize={[2, null, 3, 4]} fontWeight={700} bgColor={oppositeColors[color]} color={color}>
						{subtitle}
					</Bar>
				)}
				{description && (
					<Text
						as="div"
						fontSize={[1, null, null, 2]}
						textAlign="center"
						mt={3}
						lineHeight={1.6}
						color={color}
						sx={{ textShadow, whiteSpace: 'pre-wrap' }}
						maxWidth={[null, 600]}
					>
						{description}
					</Text>
				)}
				{specification && (
					<Text
						as="p"
						fontSize={[1, null, null, 2]}
						textAlign="center"
						mt={3}
						lineHeight={1.6}
						color={color}
						fontWeight={900}
						sx={{ textShadow }}
						maxWidth={[null, 600]}
					>
						{specification}
					</Text>
				)}
			</Description>
		</Flex>
	)
};

export default FirstSlide;
