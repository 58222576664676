import React from 'react';
import posed from 'react-pose';
import { Box, Flex } from 'rebass';
import { useColorMode } from "theme-ui";
import { times } from 'ramda';
import { alpha } from "@theme-ui/color";
import { THEME_TRANSITION_DURATION } from "../border";

export const Overlay = posed(Box)({
	intensive: {
		opacity: 0.6,
	},
	medium: {
		opacity: 0.4,
	},
	hidden: {
		opacity: 0,
	},
});

const Line = posed(Box)({
	active: {
		opacity: 1,
	},
	hidden: {
		opacity: 0,
	},
});

const Blurred = posed(Box)({
	darken: {
		filter: 'blur(10px) grayscale(0) brightness(50%)',
	},
	lighten: {
		filter: 'blur(10px) grayscale(0) brightness(100%)',
	},
	lightenGrayscale: {
		filter: 'blur(10px) grayscale(1) brightness(100%)',
	},
	darkenGrayscale: {
		filter: 'blur(10px) grayscale(1) brightness(50%)',
	},
	blurred: {
		filter: 'blur(10px) grayscale(0) brightness(100%)',
	},
	blurredGrayscale: {
		filter: 'blur(10px) grayscale(1) brightness(100%)',
	},
	transparent: {
		filter: 'blur(0px) grayscale(0) brightness(100%)',
	}
});

const Blur = ({ grayscale = true, children, active, darken, lighten, ...rest }) => {
	const [colorMode] = useColorMode();
	let pose = 'transparent';
	if (active) {
		if (grayscale) {
			if (darken && colorMode === 'dark') {
				pose = 'darkenGrayscale';
			} else if (lighten) {
				pose = 'lightenGrayscale'
			} else {
				pose = 'blurredGrayscale';
			}
		} else {
			if (darken && colorMode === 'dark') {
				pose = 'darken';
			} else {
				if (lighten) {
					pose = 'lighten'
				} else {
					pose = 'blurred';
				}
			}
		}
	}
	return (
		<Flex justifyContent="space-around" {...rest}>
			<Blurred pose={pose} sx={{  position: 'absolute', backgroundColor: 'white', top: 0, left: 0, height: '100%', width: '100%', zIndex: 1 }}>
				{lighten ? (
					<Overlay
						pose={lighten && colorMode === 'default' && active ? 'medium' : 'hidden'}
						sx={{ position: 'absolute', backgroundColor: 'white', top: 0, left: 0, height: '100%', width: '100%', zIndex: 1 }}
					/>
					) : null
				}
				{children}
			</Blurred>
			{times(i => (
				<Line
					as="span"
					pose={active ? 'active' : 'hidden'}
					key={i}
					sx={{
						display: 'block',
						height: '100%',
						width: 1,
						zIndex: 1,
						transition: `background ${THEME_TRANSITION_DURATION}ms linear`,
						bg: alpha(colorMode === 'dark' ? 'white' : 'blackest', 0.05),
						':nth-of-type(n + 3)': {
							display: ['none', null, 'block']
						},
						':nth-of-type(n + 5)': {
							display: ['none', 'block']
						}
					}}
				/>), 6)}
		</Flex>
	);
}

export default Blur;
