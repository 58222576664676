import posed from "react-pose";
import { Flex, Heading } from "rebass";
import { transitionDuration } from "../loader";

export const HeaderContainer = posed(Heading)({
	hidden: {

	},
	active: {
		staggerChildren: 300
	}
});

export const blurredConfig = {
	hidden: {
		filter: 'blur(20px)',
		transition: {
			duration: 0
		}
	},
	active: {
		filter: 'blur(0px)',
		transition: {
			delay: transitionDuration
		}
	}
};

export const BlurredSpan = posed.span(blurredConfig);

export const descriptionConfig = {
	hidden: {
		opacity: 0,
		y: '50px',
		transition: {
			duration: 0
		}
	},
	active: {
		opacity: 1,
		y: '0px',
		transition: {
			delay: 500 + transitionDuration,
			duration: 700,
		}
	}
};

export const Description = posed(Flex)(descriptionConfig);
