import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { Box, Flex } from 'rebass';
import BackgroundImage from '../background';
import LogoFull from '../logoFull';
import { find, pathOr, times, includes, pipe, concat, map, filter } from "ramda";
import posed from "react-pose";
import reducer, { initialState } from "./reducer";
import { hideBackground, hideLoader, hideTransition, setProgress, setQuoteIndex, showBackground } from "./actions";
import { graphql, StaticQuery } from "gatsby";
import { getFieldsByName, removeTrailingSlash } from "../../utils";
import { useColorMode } from "theme-ui";
import Quotes from "./quotes";

export const transitionDuration = 500;
export const idle = 500;
export const loaderDuration = 6000;

export const LoaderContext = createContext({
	value: [initialState, () => null, transitionDuration, idle]
});

const transitionTransition = {
	duration: transitionDuration,
	ease: 'linear'
};

const Container = posed(Box)({
	hidden: {
		opacity: 0,
		applyAtEnd: { display: 'none' },
		transition: {
			delay: transitionDuration,
			duration: 0,
		}
	},
	active: {
		applyAtStart: { display: 'block' },
		transition: {
			duration: 0,
		},
		opacity: 1
	}
});

const Line = posed(Box)({
	hidden: {
		scaleY: 0,
		transition: transitionTransition
	},
	active: {
		scaleY: 1,
		transition: transitionTransition
	}
});

const quoteMap = {
	'/qui-sommes-nous': (data) => pathOr([], ['nodeQuiSommesNous', 'relationships', 'quote'], data),
	'/expertise': (data) => pathOr([], ['nodeExpertise', 'relationships', 'quote'], data),
	'/realisations': (data) => pathOr([], ['nodeRealisations', 'relationships', 'quote'], data),
	'/actualite': (data) => pathOr([], ['nodeActualite', 'relationships', 'quote'], data),
	'/contact': (data) => pathOr([], ['nodeContact', 'relationships', 'quote'], data),
	'/tea-time': (data) => pathOr([], ['configPagesGameSettings', 'relationships', 'quote'], data),
};

const getQuoteFromArticle = (data, url) => pipe(
	data => concat(pathOr([], ['articles', 'nodes'], data), pathOr([], ['posts', 'nodes'], data)),
	find(article => includes(removeTrailingSlash(pathOr('', ['url'], article).replace(process.env.GATSBY_PATH_PREFIX, '')), url)),
	pathOr([], ['relationships', 'quote'])
)(data);

const Loader = ({ children, pathname, data }) => {
	const [state, dispatch] = useReducer(reducer, initialState, undefined);
	const timeoutRef = useRef(0);
	const { transitionPose, loaderActive, quoteIndex, to } = state;
	const [colorMode] = useColorMode();
	const path = to || pathname;

	// clean up
	useEffect(() => () => void clearTimeout(timeoutRef.current), []);

	const quotes = useMemo(
		() =>
			filter(({ description, subtitle }) => description || subtitle,
				map(
					q => getFieldsByName(['subtitle', 'description'], q, colorMode),
					pathOr(
						getQuoteFromArticle,
						[Object.keys(quoteMap).find(k => path.includes(k))],
						quoteMap)(data, path)
				)
			),
		[data, path, colorMode]
	);

	useEffect(() => {
		if (loaderActive) {
			const timeout = setTimeout(() => {
				dispatch(hideLoader());
			}, loaderDuration);

			let i = 0;
			const interval = setInterval(() => {
				if (i < 6) {
					dispatch(setProgress(100 * ++i / 6));
				} else {
					clearInterval(interval);
				}
			}, 800);

			dispatch(setProgress(0));

			return () => {
				clearTimeout(timeout);
				clearInterval(interval);
			};
		}
	}, [loaderActive, dispatch, path]);

	useEffect(() => {
		if (loaderActive && quotes.length && quotes.length > 1) {
			let index = 0;
			const interval = setInterval(() => void dispatch(setQuoteIndex(++index % quotes.length)), loaderDuration);
			return () => void clearInterval(interval);
		}
	}, [quotes.length, loaderActive]);

	return (
		<LoaderContext.Provider
			value={[state, dispatch, transitionDuration, idle]}
		>
			<Container
				pose={transitionPose}
				width="100%"
				height="100%"
				sx={{ position: 'absolute', top: 0, left: 0, zIndex: 32 }}
				onPoseComplete={(pose) => {
					if (pose === 'active') {
						dispatch(loaderActive ? hideBackground() : showBackground());
						timeoutRef.current = setTimeout(() => dispatch(hideTransition()), idle);
					}
				}}
			>
				{times((i) => (
					<Line key={i} pose={transitionPose} width="100%" height="calc(100% / 3)" sx={{ backgroundColor: 'background' }} />
				), 3)}
			</Container>
			{loaderActive ? (
				<Flex
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					sx={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 25 }}
				>
					<BackgroundImage />
					{(quotes.length) ? (
						<Quotes quotes={quotes} quoteIndex={quoteIndex} />) : <LogoFull mb={4} sx={{ zIndex: 1 }} />}
						<Box
							height={3}
							width="20vw"
							bg="text"
							sx={{ zIndex: 1, transition: 'transform 0.5s ease-in' }}
							style={{ transform: `scaleX(${(state.progress / 100).toFixed(2)})` }}
							mt={3}
						/>
				</Flex>
				)
				: null
			}
			{children}
		</LoaderContext.Provider>
	);
};

export default (props) => (
	<StaticQuery
		query={graphql`
			query LoaderComponentQuery {
 				nodeQuiSommesNous {
					relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
							subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
				}
				nodeActualite {
					relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
								subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
				}
				nodeRealisations {
					relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
								subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
				}
				nodeExpertise {
					relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
								subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
				}
				nodeContact {
      		relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
								subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
      	}
      	configPagesGameSettings {
					relationships {
						quote: field_quote {
							subtitle: field_title
							description: field_description_brut
								subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
						}
					}
				}
				articles: allNodeArticle {
					nodes {
						url: belazar_original_url
						relationships {
							quote: field_quote {
								subtitle: field_title
								description: field_description_brut
									subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
							}
						}
					}
				}
				posts: allNodePoste {
					nodes {
						url: belazar_original_url
						relationships {
							quote: field_quote {
								subtitle: field_title
								description: field_description_brut
									subtitle_dark: field_dark_titre
         			description_dark: field_dark_description_brut
							}
						}
					}
				}
			 }
		`}
		render={(data) => (
			<Loader data={data} {...props} />
		)}
	/>
);

export const useLoader = () => useContext(LoaderContext);