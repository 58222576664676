import React, { useEffect, useRef, useState } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from 'rebass';
import posed from 'react-pose';
import { type } from 'ramda';
import theme from "../../gatsby-plugin-theme-ui";
import Scroll from "../scroll";
import { useMediaQuery } from "react-responsive";
import { isMobile } from 'react-device-detect';
import SoundButton from '../soundButton';
import { useMenu } from '../menu';

const Arrow = posed(Box)({
 active: { scaleX: 2 },
 base: { scaleX: 1 }
});

const PosedSection = posed(Box)({
	active: {
		opacity: 1,
	},
	hidden: {
		opacity: 0
	}
})

const SoundBtnContainer = posed(Box)({
	active: {
		opacity: 1,
		applyAtStart: { display: 'block' },
		transition: {
			ease: 'easeIn',
			delay: 300,
		}
	},
	hidden: {
		opacity: 0,
		applyAtEnd: { display: 'none' },
		transition: {
			ease: 'easeOut',
		}
	}
});

const Fullpage = ({ background,  sections = [() => null], scrollProps, soundBtnProps, hideScroll }) => {
	const { menuOpened } = useMenu();

	const [activeSlide, setSlide] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const fullpageRef = useRef(null);
	const blurred = activeSlide !== 0;

	const isTabletOrMobile = useMediaQuery({ maxWidth: theme.breakpoints[1] }) || isMobile;

	useEffect(() => {
		if (fullpageRef.current) {
			fullpageRef.current.setAutoScrolling(!isTabletOrMobile);
			fullpageRef.current.setFitToSection(!isTabletOrMobile);
		}
	}, [isTabletOrMobile, fullpageRef]);

	const pose = loaded ? 'active' : 'hidden';

	return (
		<>
			<PosedSection
				pose={pose}
				sx={{
					display: ['none !important', null, 'flex !important'],
					position: 'fixed',
					flexDirection: 'column',
					left: [theme.space[2] + 'px',
						null, theme.space[3]],
					bottom: `calc(${theme.space[3]}px + 5%)`,
					zIndex: 5
				}}
				width={20}
			>
				{sections.map((_, i) => (
					<Arrow
						onClick={() => {
							if (fullpageRef.current && type(fullpageRef.current.moveTo) === 'Function') {
								fullpageRef.current.moveTo(i + 1);
							}
						}}
						as="button"
						key={i}
						mb={2}
						p={0}
						height={2}
						sx={{ backgroundColor: 'text', transformOrigin: 'left center' }}
						pose={i === activeSlide ? 'active' : 'base'}
					/>
				))}
			</PosedSection>
			<Scroll active={!blurred && !hideScroll} {...scrollProps} />
			{!!soundBtnProps && (
				<SoundBtnContainer
					pose={!blurred ? 'active' : 'hidden'}
					sx={{
						position: 'absolute',
						right: 0,
						cursor: 'pointer',
						transform: 'translateX(-50%)',
						zIndex: menuOpened ? 1 : 11,
						...(isTabletOrMobile ? { top: 0 } : { bottom: 38 }),
					}}
				>
					<SoundButton {...soundBtnProps} />
				</SoundBtnContainer>
			)}
			<Box
				sx={{
					position: 'fixed',
					left: 0,
					top: 0,
					height: '100vh',
					width: '100vw',
				}}
			>
				{background({ blurred })}
			</Box>
			<ReactFullpage
				licenseKey={process.env.GATSBY_FULLPAGEJS_LICENSE_KEY}
				onLeave={(_, { index }) => setSlide(index)}
				autoScrolling={!isTabletOrMobile}
				fitToSection={!isTabletOrMobile}
				afterRender={() => setLoaded(true)}
				render={({ fullpageApi, state }) => {
					fullpageRef.current = fullpageApi;
					return sections.map((section, i) => <PosedSection sx={{ opacity: 0 }} pose={pose} className="section" key={i}>{section({ fullpageApi, state, blurred, active: activeSlide === i })}</PosedSection>)
				}}
			/>
		</>
	)
};

export default Fullpage;
