// import LoaderLink from "../components/loaderLink";
import theme from "../gatsby-plugin-theme-ui";
import { Link } from 'gatsby';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useMemo } from "react";
import { path } from 'ramda';

export const getLinkWithComponent = (url = '') => {
	const isInternal = !!url.search(/^https?:/);
	if (!isInternal) {
		return {
			as: OutboundLink,
			href: url,
			target: '_blank'
		}
	} else {
		return {
			as: Link,
			to: url.replace(/^(internal|entity):/, '/')
		}
	}
};

export const formatTime = (seconds) => {
	const date = new Date(seconds * 1000);
	return [date.getUTCMinutes(), date.getSeconds()].map(s => `${s}`.padStart(2, '0')).join(':');
};

export const getThemeColor = (color, colorMode = 'default') => colorMode === 'default' ? theme.colors[color] : theme.colors.modes[colorMode][color];

export const getComputedTranslateX = (obj) => {
	if (!window.getComputedStyle) return;
	const style = getComputedStyle(obj),
		transform = style.transform || style.webkitTransform || style.mozTransform;
	let mat = transform.match(/^matrix3d\((.+)\)$/);
	if (mat) return parseFloat(mat[1].split(', ')[12]);
	mat = transform.match(/^matrix\((.+)\)$/);
	return mat ? parseFloat(mat[1].split(', ')[4]) : 0;
};

export const removeTrailingSlash = str => str.replace(/\/$/, "");

export const calculateImageSize = (cWidth, cHeight, width, height) => {
	const oRatio = width / height;
	return function() {
		this.width = cHeight * oRatio;
		this.height = cHeight;
		this.x = (cWidth- this.width) / 2;
		this.y = (cHeight - this.height) / 2;
		return this;
	}.call({});
};

export const getFieldsByName = 	(names = [], config = {}, colorMode) => names.reduce(
	(res, name) => ({ ...res, [name]: colorMode === 'default' ? config[name] : config[`${name}_dark`] || config[name] }), {});

export const useGetFieldsByName = (names = [], config = {}, colorMode) =>
	// eslint-disable-next-line
	useMemo(() => getFieldsByName(names, config, colorMode), [config, colorMode]);

export const getOgImage = path(['sharp', 'fixed', 'src']);
