import { useEffect, useState } from "react";

const useFirstPose = () => {
	const [pose, setPose] = useState('hidden');

	useEffect(() => {
		setPose('active');
	}, []);

	return pose;
};

export default useFirstPose;