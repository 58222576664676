import React from 'react';
import { Box } from 'rebass';
import GatsbyImage from "gatsby-image";

const Image = React.forwardRef(({ sx, fluid, fixed, loading, objectFit, imgStyle, ...props }, ref) => (
	<Box {...props} sx={{ ...sx, '> div': { height: '100%', width: '100%' } }} ref={ref}>
		<GatsbyImage
			loading={loading}
			fluid={fluid}
			fixed={fixed}
			objectFit={objectFit}
			imgStyle={imgStyle}
		/>
	</Box>
));

export default Image;
