import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Flex, Heading } from 'rebass';
import Bar from "../bar";
import { prop } from "ramda";
import theme from "../../gatsby-plugin-theme-ui";
import posed from "react-pose";

const PosedQuote = posed(Flex)({
	active: {
		opacity: 1,
		transition: {
			duration: 700,
		}
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 700,
		}
	}
});

const Quotes = ({ quotes, quoteIndex }) => {
	const refs = useRef([]);
	const [rect, setRect] = useState({ width: 0, height: 0 });

	const [calculated, setCalculated] = useState(false);

	useLayoutEffect(() => {
		setCalculated(false);
		const timeout = setTimeout(() => {
			const rects = quotes.map((_, i) => refs.current[i].getBoundingClientRect());
			const width = Math.max(...rects.map(prop(['width'])));
			const height = Math.max(...rects.map(prop(['height']))) + theme.space[5];

			setRect({ width, height });
			setCalculated(true);
		}, 300);
		return () => clearTimeout(timeout);
	}, [quotes]);

	return (
		<Box style={{ ...rect, position: calculated ? 'relative' : 'static', opacity: calculated ? 1 : 0 }}>
			{quotes.map(
				({ description, subtitle }, i) => (
					<PosedQuote
						pose={calculated && i === quoteIndex ? 'active' : 'hidden'}
						key={i}
						ref={ref => refs.current[i] = ref}
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
						sx={{ zIndex: 1, textAlign: 'center', position: 'absolute', left: 0, top: 0, opacity: 0, maxWidth: '80vw' }}
						style={calculated ? rect : { width: 'auto', height: 'auto'}}
					>
						{description ? <Heading fontSize={[6, null, null, 7]} sx={{ whiteSpace: 'pre-wrap' }} mb={4}>{`« ${description} »`}</Heading> : null}
						{subtitle ? <Bar rotate={0} fontSize={[2, null, 3, 4]}>{subtitle}</Bar> : null}
					</PosedQuote>
				)
			)}
		</Box>
	)
};

export default Quotes;