import React from 'react';
import { Box } from 'rebass';
import LogoSvg from '../../images/icons/logo-full.svg';
import LogoGif from '../../assets/logoFull.gif';

const LogoFull = ({ sx = {}, ...props }) => {
	return (
		<Box
			width={[250, 350, 450]}
			sx={{
				position: 'relative',
				svg: {
					width: '100%',
					height: 'auto',
					'path, polygon, rect': { fill: 'text' },
				},
				...sx,
		}}
			{...props}
		>
			<LogoSvg />

			<img
				src={LogoGif}
				style={{ position: 'absolute', left: 0, width: '100%', height: '98%' }}
				onError={(e) => {
					e.target.style.display = 'none';
				}}
			/>
		</Box>
	);
};

export default LogoFull;
