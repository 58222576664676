import React from 'react';
import { Flex } from 'rebass';
import posed from 'react-pose';
import theme from '../../gatsby-plugin-theme-ui';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';

const posedIcon = {
  initial: {
    scale: 1,
  },
  hover: {
    scale: 1.1,
  },
  active: {
    scale: 0.8,
  }
}

const PosedIcon = posed(Flex)(posedIcon);

const SoundButton = (props) => {
  const {
    soundEnabled,
    setSoundEnabled,
    soundActive,
    setSoundActive,
    soundHovered,
    setSoundHovered,
    iconSize = 30,
    color = theme.colors.black,
    onClick,
    styles,
  } = props;

  const Icon = soundEnabled ? BsFillVolumeUpFill : BsFillVolumeMuteFill;

  return (
    <PosedIcon
      justifyContent="center"
      alignItems="center"
      pose={soundActive ? 'active' : (soundHovered ? 'hover' : 'initial')}
      onClick={() => {
        setSoundEnabled(a => !a);
        onClick && onClick();
      }}
      onMouseEnter={() => setSoundHovered(true)}
      onMouseLeave={() => {
        setSoundHovered(false);
        setSoundActive(false);
      }}
      onMouseDown={() => setSoundActive(true)}
      onMouseUp={() => setSoundActive(false)}
      mt={[3]}
      sx={{
        zIndex: 2,
        border: '1px solid',
        borderColor: color,
        width: 35,
        height: 35,
        ...styles,
      }}
    >
      <Icon
        size={iconSize}
        color={color}
      />
    </PosedIcon>
  );
};

export default SoundButton;
