export const initialState = {
	transitionPose: 'hidden',
	loaderActive: true,
	shouldBeClosed: false,
	transitionCompleted: false,
	progress: 0,
	quoteIndex: 0,
	modelLoading: false,
	to: null,
};

export default (state, action) => {
	switch (action.type) {
		case 'SHOW_LOADER':
			return { ...state, transitionPose: 'active', transitionCompleted: false, to: action.payload || null  };
		case 'SHOW_BACKGROUND':
			return { ...state, loaderActive: true };
		case 'HIDE_BACKGROUND':
			return { ...state, loaderActive: false, quote: null, quoteIndex: 0, };
		case 'HIDE_TRANSITION':
			return { ...state, transitionCompleted: true, transitionPose: 'hidden' };
		case 'HIDE_LOADER':
			if (state.loaderActive) {
				if (!state.modelLoading) {
					return { ...state, transitionPose: 'active', transitionCompleted: false, held: false, to: null };
				} return { ...state, shouldBeClosed: true }
			}
			return state;
		case 'LOAD_MODEL':
			return { ...state, modelLoading: true };
		case 'MODEL_LOADED':
			if (state.shouldBeClosed) {
				return { ...state, transitionPose: 'active', transitionCompleted: false, held: false, to: null, modelLoading: false, shouldBeClosed: false }
			}
			return { ...state, modelLoading: false };
		case 'SET_PROGRESS':
			return { ...state, progress: action.payload };
		case 'SET_QUOTE_INDEX':
			return { ...state, quoteIndex: action.payload };
		default:
			return state;
	}
}