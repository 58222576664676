import React from "react";
import { Box } from 'rebass';
import theme from "../../gatsby-plugin-theme-ui";
import posed from "react-pose";
import { THEME_TRANSITION_DURATION } from "../border";

const ScrollContainer = posed(Box)({
	active: {
		opacity: 1,
		applyAtStart: { display: 'block' },
		transition: {
			ease: 'easeIn',
			delay: 300,
		}
	},
	hidden: {
		opacity: 0,
		applyAtEnd: { display: 'none' },
		transition: {
			ease: 'easeOut',
		}
	}
});

const Dot = posed(Box)({
	active: {
		y: 5,
		transition: {
			type: 'keyframes',
			values: [5, 13, 5, 13, 5, 13, 5],
			duration: 2000,
			// times: [0.04, 0.08, 0.12, 0.16, 0.2, 0.24, 0.28],
			delay: 100,
			loop: Infinity,
		}
	}
});

const Scroll = ({ active, color = 'text' }) => {

	const pose = active ? 'active' : 'default';

	return (
		<ScrollContainer
			pose={active ? 'active' : 'hidden'}
			sx={{
				position: 'fixed',
				left: '50%',
				bottom: 54,
				transform: 'translateX(-50%)',
				zIndex: 5,
			}}
		>
			{/* desktop */}
			<Box
				sx={{
					display: ['none', null, 'block'],
					position: 'relative',
					width: 18,
					height: 28,
					border: "2px solid",
					borderRadius: 9999,
					borderColor: color,
					transition: `border-color ${THEME_TRANSITION_DURATION}ms linear`,
				}}
			>
				<Dot
					pose={pose}
					initialPose='active'
					sx={{
						position: 'absolute',
						height: '6px',
						width: '2px',
						top: '0',
						transform: 'translateY(5px)',
						left: '6px',
						bg: color,
						transition: `background-color ${THEME_TRANSITION_DURATION}ms linear`,
						borderRadius: 1
					}}
				/>
			</Box>
			{/* mobile */}
			<Box
				sx={{
					display: ['block', null, 'none'],
					color,
					textTransform: 'uppercase',
					position: 'relative',
					fontSize: 11,
					transition: `color ${THEME_TRANSITION_DURATION}ms linear`,
					':after': {
						content: "''",
						position: 'absolute',
						top: `calc(100% + 6px)`,
						width: 1,
						height: 54 - theme.space[2] - 6,
						bg: color,
						transition: `background-color ${THEME_TRANSITION_DURATION}ms linear`,
						left: '50%',
						transform: 'translateX(-50%)'
					}
				}}
			>
				scroll
			</Box>
		</ScrollContainer>
	);
};

export default Scroll;
